import React, { ReactNode } from "react";
import arithmen2Image from "~/images/arithmenillustrations-02.png";
import arithmen4Image from "~/images/arithmenillustrations-04.png";
import arithmen6Image from "~/images/arithmenillustrations-06.png";

type Props = {
  readonly children?: ReactNode;
};

function DownloadSection({ children }: Props) {
  return (
    <section
      style={{ backgroundColor: "#e37262" }}
      id="download-links"
      className="Index-page"
      data-collection-id="62dea2722ab9183c04563504"
      data-edit-main-image="Background"
    >
      <div className="Index-page-content sqs-alternate-block-style-container">
        <div className="sqs-layout sqs-grid-12 columns-12" data-type="page">
          <div className="row sqs-row" id="yui_3_17_2_1_1671150646035_121">
            <div className="col sqs-col-12" id="yui_3_17_2_1_1671150646035_120">
              <div
                className="sqs-block html-block sqs-block-html"
                data-block-type="2"
                id="block-d99ed108b8dd69f5fd03"
              >
                <div className="sqs-block-content">
                  <h2
                    style={{
                      textAlign: "center",
                      whiteSpace: "pre-wrap",
                      color: "white",
                    }}
                  >
                    For students who say…
                  </h2>
                </div>
              </div>
              <div className="row sqs-row" id="yui_3_17_2_1_1671150646035_119">
                <div
                  className="col sqs-col-4"
                  id="yui_3_17_2_1_1671150646035_118"
                >
                  <div
                    className="sqs-block image-block sqs-block-image sqs-text-ready"
                    data-block-type="5"
                    id="block-987403e7d50557468ca9"
                  >
                    <div
                      className="sqs-block-content"
                      id="yui_3_17_2_1_1671150646035_117"
                    >
                      <div
                        className="image-block-outer-wrapper
                        layout-caption-below
                        design-layout-inline
                        combination-animation-none
                        individual-animation-none
                        individual-text-animation-none
                        sqs-narrow-width"
                        data-test="image-block-inline-outer-wrapper"
                        id="yui_3_17_2_1_1671150646035_116"
                      >
                        <figure
                          className="sqs-block-image-figure intrinsic"
                          style={{ maxWidth: 5864 }}
                          id="yui_3_17_2_1_1671150646035_115"
                        >
                          <div
                            className="image-block-wrapper"
                            data-animation-role="image"
                            id="yui_3_17_2_1_1671150646035_114"
                          >
                            <div
                              className="sqs-image-shape-container-element has-aspect-ratio"
                              style={{
                                position: "relative",
                                paddingBottom: "75.64802551269531%",
                                overflow: "hidden",
                              }}
                              id="yui_3_17_2_1_1671150646035_113"
                            >
                              <noscript>
                                <img src={arithmen2Image} alt="" />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={arithmen2Image}
                                data-image={arithmen2Image}
                                data-image-dimensions="5864x4436"
                                data-image-focal-point="0.5,0.5"
                                alt=""
                                data-load="false"
                                data-image-id="62dea2712ab9183c045634f3"
                                data-type="image"
                                style={{
                                  left: "0%",
                                  top: "-0.044076%",
                                  width: "100%",
                                  height: "100.088%",
                                  position: "absolute",
                                }}
                                data-image-resolution="500w"
                                src={arithmen2Image}
                              />
                            </div>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col sqs-col-4"
                  id="yui_3_17_2_1_1671150646035_146"
                >
                  <div
                    className="sqs-block image-block sqs-block-image sqs-text-ready"
                    data-block-type="5"
                    id="block-69a23b74b979b1f0ca5d"
                  >
                    <div
                      className="sqs-block-content"
                      id="yui_3_17_2_1_1671150646035_145"
                    >
                      <div
                        className="image-block-outer-wrapper
        layout-caption-below
        design-layout-inline
        combination-animation-none
        individual-animation-none
        individual-text-animation-none
        sqs-narrow-width"
                        data-test="image-block-inline-outer-wrapper"
                        id="yui_3_17_2_1_1671150646035_144"
                      >
                        <figure
                          className="
            sqs-block-image-figure
            intrinsic
          "
                          style={{ maxWidth: 5864 }}
                          id="yui_3_17_2_1_1671150646035_143"
                        >
                          <div
                            className="image-block-wrapper"
                            data-animation-role="image"
                            id="yui_3_17_2_1_1671150646035_142"
                          >
                            <div
                              className="sqs-image-shape-container-element
            
        
      
            has-aspect-ratio
          "
                              style={{
                                position: "relative",
                                paddingBottom: "73.6357421875%",
                                overflow: "hidden",
                              }}
                              id="yui_3_17_2_1_1671150646035_141"
                            >
                              <noscript>
                                <img src={arithmen4Image} alt="" />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={arithmen4Image}
                                data-image={arithmen4Image}
                                data-image-dimensions="5864x4318"
                                data-image-focal-point="0.5,0.5"
                                alt=""
                                data-load="false"
                                data-image-id="62dea2712ab9183c045634f6"
                                data-type="image"
                                style={{
                                  left: "0%",
                                  top: "-0.0606636%",
                                  width: "100%",
                                  height: "100.121%",
                                  position: "absolute",
                                }}
                                data-image-resolution="500w"
                                src={arithmen4Image}
                              />
                            </div>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col sqs-col-4"
                  id="yui_3_17_2_1_1671150646035_165"
                >
                  <div
                    className="sqs-block image-block sqs-block-image sqs-text-ready"
                    data-block-type="5"
                    id="block-c153468f983d29407cd4"
                  >
                    <div
                      className="sqs-block-content"
                      id="yui_3_17_2_1_1671150646035_164"
                    >
                      <div
                        className="image-block-outer-wrapper
        layout-caption-below
        design-layout-inline
        combination-animation-none
        individual-animation-none
        individual-text-animation-none
        sqs-narrow-width"
                        data-test="image-block-inline-outer-wrapper"
                        id="yui_3_17_2_1_1671150646035_163"
                      >
                        <figure
                          className="
            sqs-block-image-figure
            intrinsic
          "
                          style={{ maxWidth: 5843 }}
                          id="yui_3_17_2_1_1671150646035_162"
                        >
                          <div
                            className="image-block-wrapper"
                            data-animation-role="image"
                            id="yui_3_17_2_1_1671150646035_161"
                          >
                            <div
                              className="sqs-image-shape-container-element has-aspect-ratio"
                              style={{
                                position: "relative",
                                paddingBottom: "75.02995300292969%",
                                overflow: "hidden",
                              }}
                              id="yui_3_17_2_1_1671150646035_160"
                            >
                              <noscript>
                                <img src={arithmen6Image} alt="" />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={arithmen6Image}
                                data-image={arithmen6Image}
                                data-image-dimensions="5843x4384"
                                data-image-focal-point="0.5,0.5"
                                alt=""
                                data-load="false"
                                data-image-id="62dea2712ab9183c045634f9"
                                data-type="image"
                                style={{
                                  left: "0%",
                                  top: "-0.0199669%",
                                  width: "100%",
                                  height: "100.04%",
                                  position: "absolute",
                                }}
                                data-image-resolution="500w"
                                src={arithmen6Image}
                              />
                            </div>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2
                style={{
                  textAlign: "center",
                  whiteSpace: "pre-wrap",
                  color: "white",
                }}
              >
                Change their thinking!
              </h2>
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadSection;
