import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./AwardsAndRecognition.css";

function AwardsAndRecognition() {
  return (
    <div className="awards-and-recognition-section">
      <div className="container">
        <h2>Awards &amp; Recognition</h2>
        <ul>
          <li>
            <StaticImage
              src="../images/awards/nsf.png"
              alt="NSF"
              width={168}
              height={132}
              layout="fixed"
              backgroundColor="transparent"
              objectFit="contain"
              placeholder="none"
              transformOptions={{ fit: "contain" }}
            />
          </li>
          <li>
            <StaticImage
              src="../images/awards/hth.png"
              alt="How to homeschool"
              width={168}
              height={132}
              layout="fixed"
              backgroundColor="transparent"
              placeholder="none"
              objectFit="contain"
              transformOptions={{ fit: "contain" }}
            />
          </li>
          <li>
            <StaticImage
              src="../images/awards/digital-promise.png"
              alt="Digital promise"
              width={168}
              height={132}
              layout="fixed"
              backgroundColor="transparent"
              placeholder="none"
              objectFit="contain"
              transformOptions={{ fit: "contain" }}
            />
          </li>
          <li>
            <StaticImage
              src="../images/awards/codie.png"
              alt="Codie"
              width={168}
              height={132}
              layout="fixed"
              backgroundColor="transparent"
              placeholder="none"
              objectFit="contain"
              transformOptions={{ fit: "contain" }}
            />
          </li>
          <li>
            <StaticImage
              src="../images/awards/edith.png"
              alt="Edith"
              width={168}
              height={132}
              layout="fixed"
              backgroundColor="transparent"
              placeholder="none"
              objectFit="contain"
              transformOptions={{ fit: "contain" }}
            />
          </li>
          <li>
            <StaticImage
              src="../images/awards/ed-21.png"
              alt="Ed 21"
              width={168}
              height={132}
              layout="fixed"
              backgroundColor="transparent"
              placeholder="none"
              objectFit="contain"
              transformOptions={{ fit: "contain" }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AwardsAndRecognition;
