import React from "react";
import Seo from "~/components/Seo";
import LearnMore from "~/features/marketing/learn-more";
import classroomsBackgroundImage from "~/images/classrooms-background.jpg";
import gameOverviewDocument from "~/images/schools-districts-game-overview.pdf";

function ClassroomsLearnMorePage() {
  return (
    <LearnMore
      headline2="ProblemScape: Value of Xperts supplements your classroom instruction and keeps all students engaged in learning algebra!"
      headlineButtons={[
        {
          to: "/contact/?source=classrooms-learn-more-request-demo",
          label: "Request a Demo",
        },
        {
          to: "/contact/?source=classrooms-learn-more-interested-in-piloting",
          label: "Interested in Piloting",
        },
        {
          to: gameOverviewDocument,
          label: "Game Overview",
          forceExternal: true,
        },
      ]}
      featuresBackgroundImage={classroomsBackgroundImage}
      features={[
        "An adventure game with real-world problems set in a virtual world to understand the applications of what they are learning",
        "Multi-level practice sessions to practice, and spaced reviews to retain, what they have learned",
        "Eight chapters that cover all of the algebra standards for sixth grade",
        "Teach virtual characters to get a deeper understanding of concepts",
      ]}
      theme="green"
    />
  );
}

export function Head() {
  return <Seo title="Classrooms - Learn More" />;
}

export default ClassroomsLearnMorePage;
