import React from "react";
import clsx from "clsx";
import StandardTestimonials from "~/components/StandardTestimonials";
import AwardsAndRecognition from "~/components/AwardsAndRecognition";
import DownloadSection from "~/components/DownloadSection";
import TitleBanner, { TitleBannerProps } from "~/components/TitleBanner";
import DownloadLinks from "~/components/DownloadLinks";
import "./learn-more.css";

type LearnMoreProps = Pick<TitleBannerProps, "theme" | "headline2"> & {
  readonly headlineButtons: TitleBannerProps["buttons"];
  readonly featuresBackgroundImage: string;
  readonly features: ReadonlyArray<string>;
};

function LearnMore({
  headline2,
  headlineButtons,
  theme,
  featuresBackgroundImage,
  features,
}: LearnMoreProps) {
  return (
    <div className="Site-inner Site-inner--index">
      <div className="Content-outer">
        <TitleBanner
          theme={theme}
          headline2={headline2}
          buttons={headlineButtons}
        />
        <section
          className={clsx(
            "learn-more-features",
            `learn-more-features-${theme}`,
          )}
          style={{
            backgroundImage: `url(${featuresBackgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            {features.map((f) => (
              <div
                key={f}
                className={clsx("learn-more-feature", {
                  "learn-more-feature-smaller": f.length < 100,
                })}
              >
                <div className="feature-bubble">{f}</div>
              </div>
            ))}
          </div>
        </section>
        <DownloadSection>
          <DownloadLinks />
        </DownloadSection>
        <AwardsAndRecognition />
        <StandardTestimonials />
      </div>
    </div>
  );
}

export default LearnMore;
